import { template as template_1a461d6b16304e2aa0536d810150707c } from "@ember/template-compiler";
const FKControlMenuContainer = template_1a461d6b16304e2aa0536d810150707c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_064e16b31964448fa5ea42a704613bb0 } from "@ember/template-compiler";
const EmptyState = template_064e16b31964448fa5ea42a704613bb0(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
